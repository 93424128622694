<template>
  <div class="newyear-container" :class="{ '_in-modal': modal }">
    <div class="main-screen">
      <div class="main-screen__welcome">
        <div class="main-screen__welcome-logo">
          <img src="@/assets/images/logotype-tomsk-big.png" />
        </div>

        <div class="main-screen__welcome-text">
          <h1>«НОВЫЙ ГОД ПО-ТОМСКИ!»</h1>
          <h2>Новогодние праздники – идеальное время для увлекательных путешествий.</h2>
          <h3>Рассказываем куда сходить в Томске, что посмотреть и попробовать!</h3>
          <h3>Спланируйте свои новогодние каникулы и не пропустите самые яркие события в Томской области!</h3>
        </div>

        <div class="main-screen__welcome-buttons">
          <a href="/wall" class="router-link-exact-active router-link-active">
            <button class="button button__transparent">СОБЫТИЯ</button>
          </a>

          <a href="/ny-offers" class="router-link-exact-active router-link-active">
            <button class="button button__transparent">СКИДКИ И АКЦИИ</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewYear',
};
</script>

<style lang="scss" scoped>
  .newyear-container {
    .main-screen {
      background-color: black;
      background-image: url("../assets/images/ny.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: calc(100vh - 80px);

      &__welcome {
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
        height: 100%;
        width: 100%;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-logo {
          padding-top: 50px;
          padding-left: 50px;
          align-self: flex-start;

          img {
            max-height: 120px;
          }
        }

        &-text {
          text-align: center;
          padding: 50px 1rem;
          display: flex;
          flex-direction: column;
          gap: 2rem;
          max-width: 800px;

          h1, h2, h3 {
            text-shadow: 1px 1px 2px black, 0 0 1em #222, 0 0 0.2em #333;
            line-height: 1.2;
          }

          h1 {
            font-size: 48px;
          }

          h2 {
            font-size: 36px;
          }

          h3 {
            font-size: 24px;
          }
        }

        &-buttons {
          display: flex;
          gap: 2rem;
          justify-content: center;
        }
      }
    }
  }

  @media (max-width: 1130px) {
    .newyear-container {
      .main-screen {
        height: calc(100vh - 56px);

        &__welcome {
          &-text {
            padding: 75px 1rem;
          }
        }
      }
    }
  }

  @media (max-width: 432px) {
    .newyear-container {
      .main-screen {
        min-height: 800px;
        height: calc(100vh - 50px);

        &__welcome {
          &-logo {
            padding-top: 2rem;
            padding-left: 2rem;

            img {
              max-height: 74px;
            }
          }

          &-text {
            padding: 2rem 1rem;
            gap: 1rem;
            line-height: 1.5;
            h1 { font-size: 36px; }
            h2 { font-size: 24px; }
            h3 { font-size: 22px; }
          }

          &-buttons {
            padding-top: 1rem;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }
</style>
